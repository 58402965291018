<template>
  <div class="orderList page-gray">
    <div class="head">
      <div class="filter" @click="timeClone = time; wayClone = way; openFilter = true">
        <span :class="{active: time !== '' || way !== ''}">筛选</span>

        <img src="./../../assets/images/order/filter_active.png" v-if="time !== '' || way !== ''" alt="">
        <img src="./../../assets/images/order/filter.png" v-else alt="">

      </div>
      <van-tabs
          v-model="active"
          sticky
          swipeable
          :line-width="15"
          :line-height="5">
        <van-tab title="全部" name="0"></van-tab>
        <van-tab title="待付款" name="1"></van-tab>
        <van-tab title="待发货" name="2"></van-tab>
        <van-tab title="待收货" name="3"></van-tab>
        <van-tab title="已完成" name="4"></van-tab>
        <van-tab title="退款/售后" name="5"></van-tab>
      </van-tabs>
    </div>
    <div class="list">
      <all-order-List key="allOrderab" v-if="active<5" :order-type="active" :query="q"/>
      <order-completed-list  v-else/>
    </div>

    <van-popup
        v-model="openFilter"
        closeable

        close-icon="close"
        position="bottom"
        round
        :style="{ height: px2rem(320) }"
    >
      <h1>订单筛选</h1>

      <div class="filter-item">
        <div class="title">下单时间</div>
        <van-row gutter="22" class="list">
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 1}" @click="timeClone=1">
              近1个月
            </div>
          </van-col>
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 3}" @click="timeClone=3">
              近3个月
            </div>
          </van-col>
          <van-col :span="8">
            <div :class="{item: true, active: timeClone == 6}" @click="timeClone=6">
              近6个月
            </div>
          </van-col>
        </van-row>
      </div>

      <div class="filter-item">
        <div class="title">配送方式</div>
        <van-row gutter="22" class="list">
          <van-col :span="8">
            <div :class="{item: true, active: q.wayClone == 1}" @click="q.wayClone=1">
              配送到校
            </div>
          </van-col>
          <van-col :span="8">
            <div :class="{item: true, active: q.wayClone == 2}" @click="q.wayClone=2">
              快递到家
            </div>
          </van-col>
        </van-row>
      </div>

      <div class="buttonGroup">
        <van-row gutter="14">
          <van-col :span="12">
            <van-button block type="info" color="#F2F2F2" round @click="timeClone = ''; q.wayClone = '';">
              <span style="color: #333;">重置</span>
            </van-button>
          </van-col>
          <van-col :span="12">
            <van-button block type="primary" color="#D53A26" round @click="confirm">确认</van-button>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import allOrderList from './listComponent/allOrderList.vue'
import OrderCompletedList from "./listComponent/orderCompletedList.vue";

export default {
  components: {OrderCompletedList, allOrderList},
  name: "OrderList",
  data() {
    return {
      active: '0',
      time: '',
      way: '',
      timeClone: '',
      openFilter: false,
      q:{
        startTime:"",
        endTime:"",
        wayClone:'',
      }

    }
  },

  methods: {
    confirm() {
      this.time = this.timeClone;
      this.way = this.q.wayClone;
      this.openFilter = false
    }
  },
  watch: {
    active: {
      handler(val) {
        if(''===val || undefined === val || null === val){
          this.endTime = '';
          this.startTime = '';
        }else{
          let today = new Date() //当天
          this.endTime = today.toLocaleTimeString();
          today.setMonth(today.getMonth() - val)//三个月前，时间戳
          this.startTime = today.toLocaleDateString()//格式化时间
        }

        this.$router.replace({
          query: {type: this.active, t: new Date().getTime()}
        })
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.$route.query.type) {
      this.active = this.$route.query.type
    }
  }
}

</script>
<style lang="less" scoped>
.head {
  background-color: #fff;
  text-align: center;
  padding-top: 8px;

  .filter {
    padding: 0 8px 0 13px;
    border-radius: 1000px;
    background-color: #DCDCDC;
    display: inline-flex;
    align-items: center;
    height: 22px;

    img {
      height: 12px;
      margin-left: 7px;
    }

    span {
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      color: #FFFFFF;
      display: block;
      width: 25px;
      padding-bottom: 2px;

      &.active {
        color: #DE4547;
      }
    }
  }
}

.orderList {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .list {
    flex: 1;
    overflow-y: auto;
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 24px;
}

h1 {
  line-height: 1;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;
  margin-top: 24px;
  text-align: center;
}

.filter-item {
  margin-top: 35px;
  padding: 0 17px;

  .title {
    font-size: 15px;
    font-weight: 500;
    color: #212121;
    line-height: 1;
    margin-bottom: 13px;
  }

  .list {
    .item {
      height: 32px;
      width: 100%;
      border-radius: 16px;

      font-size: 12px;
      font-weight: 400;

      background: #EEEEEE;
      color: #333333;
      border: 1px solid #EEEEEE;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: #D53A26;
        background: #F8EDEB;
        border: 1px solid #D61619;
      }
    }
  }

}

.buttonGroup {
  padding: 30px 18px 0 18px;
}
</style>
