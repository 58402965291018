<template>
  <van-list
      v-model="tableLoading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :key="timer"
  >
    <order-item v-for="item in tableData" :item="item"/>
  </van-list>
</template>

<script>
import orderItem from './orderItem.vue';

export default {
  components: {orderItem},
  name: "AllList",
  props: {
    orderType: {},
    query: {
      type: Object,
      "default": {},
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      timer:'',
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5 // 每页显示多少条
      },
      tableLoading: false,
      refreshing: false,
      finished: false,
      //加载数据
      tableData: [],
    };
  },
  filters: {
    orderType(val) {
      switch (val) {
        case "1":
          return "等待买家付款";
        case "2":
          return "生产中";
        case "3":
          return "配送中";
        case "4":
          return "部分发货";
        case "5":
          return "已签收";
        case "6":
          return "退款成功";
        case "7":
          return "取消订单";
      }
    },
  },
  methods: {
    //分页
    getList(page, params) {
      this.tableLoading = true
      this.$http.get(this.baseMallUrl + "/h5_mall_order/queryProductExaminationList", {
        params: Object.assign({
          current: page.currentPage,
          size: page.pageSize
        }, params)
      }, {
        emulateJSON: true
      }).then(response => {
        if(page.currentPage == 1){
          this.tableData = response.data.records
        }else{
          this.tableData = this.tableData.concat(response.data.records)
        }
        this.page.total = response.data.total
        if (this.tableData.length >= this.page.total) {
          this.finished = true;
        } else {
          this.page.currentPage++
        }
        this.tableLoading = false
      }).catch(() => {
        this.tableLoading = false
        // this.finished = true;
      })
    },
    onLoad() {
      let query = this.query;
      query.active = this.orderType
      this.getList(this.page, query)
    },
  },
  mounted() {
    this.page={
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 5 // 每页显示多少条
    };
    this.tableData = [];
    this.finished = false;
  },
  watch: {
    orderType: {
      handler() {
        this.tableData = []
        this.page = {
          total: 0, // 总页数
          currentPage: 1, // 当前页数
          pageSize: 5 // 每页显示多少条
        };
        this.finished = false
        this.onLoad()
      }
    }
  },
};
</script>
<style lang="less" scoped>

</style>
